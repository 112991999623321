/**
 * ### New version {{#crossLink "Services.services.copyrightsV2"}}copyrightsV2{{/crossLink}}
 * The new {{#crossLink "Services.services.copyrightsV2"}}copyrightsV2{{/crossLink}} service was introduced.
 * We highly recommend using the new V2 version.
 *
 * * ### Old version
 * * __The copyrights service has been deprecated.__
 * * This service will be withdrawn following a 12 months deprecation period.
 * * The planned withdrawal period is June of 2022.
 * * Following withdrawal, requests to this service may receive an HTTP 404 error in response.
 *
 * The Copyrights service implementation provides a full copyrights notice in a HTML format that can be displayed to
 * the user when the copyrights link is clicked.
 *
 * The Copyrights API can take as an argument an area which concerns the copyrights. If this argument is omitted
 * the whole world is considered.
 *
 * The API handles the "edge of the world" issue. It allows the proper handling of bounding box that is outside of world
 * extents (-180, -90, 180, 90).
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The final response from the service is a HTML string with the copyrights information that have to
 * be displayed.
 * The whole respose is wrapped into a `div` element with the id `copyrightMessage`. Country names are
 * enclosed within `h4` elements and the copyrights information per each supplier is enclosed in `p`
 * elements. This makes styling with CSS fairly easy. An example response may look like this:
 *```html
 * <div id="copyrightMessage"><h4>General Copyrights:</h4>
 *   <p>© 1992 – 2018 TomTom. All rights reserved. This
 *   material is proprietary and the subject of
 *   copyrights protection, database right protection
 *   and other intellectual property rights owned
 *   by TomTom or its suppliers. The use of
 *   this material is subject to the terms of a license
 *   agreement. Any unauthorized copying or disclosure
 *   of this material will lead to criminal and civil
 *   liabilities.</p>
 *   <p>Data Source © 2018 TomTom</p>
 * </div>
 * ```
 *
 * The response is also extended by `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * @example
 * ```js
 * //a bounding box copyrights call
 * tt.services.copyrights({
 *   key: <Your API key>,
 *   boundingBox: '0, 0, 1, 1'
 * }).then(function(response) {
 *     console.log(response);
 *   });
 *
 * //a tile-level copyrights call
 * tt.services.copyrights({
 *   key: <Your API key>,
 *   zoom: 1,
 *   x: 1,
 *   y: 1
 * }).then(function(response) {
 *     console.log(response);
 *   });
 * ```
 *
 * @class copyrights
 * @namespace Services.services
 * @module Services
 * @constructor
 * @uses KeyMixin
 * @uses TrackingIdMixin
 * @uses BoundingBoxMixin
 * @uses ProtocolMixin
 * @uses ZoomMixin
 * @uses XMixin
 * @uses YMixin
 * @uses AbortSignalMixin
 *
 * @param {Object} [options] Options to be passed to the call
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import copyrightsParameters from 'services/copyrights/copyrightsParameters';
import { modelResponse } from '../../model/modelResponse';
import { Endpoints } from '../../endpoints/endpointsManager';
import { singleRequestServiceFactory, serviceFactory } from '../../core';

const fields = copyrightsParameters.create();

export function copyrights(options, additionalOptions) {
    const endpoints = new Endpoints(additionalOptions);
    const copyrightsBounds = singleRequestServiceFactory(endpoints.resolve('copyrightsBoundsEndpoint'));
    const copyrightsZoom = singleRequestServiceFactory(endpoints.resolve('copyrightsZoomEndpoint'));
    const copyrightsWorld = singleRequestServiceFactory(endpoints.resolve('copyrightsWorldEndpoint'));

    function copyrightsQuery(fields, options, abortSignal) {
        if (options.boundingBox) {
            return copyrightsBounds(fields, options, abortSignal);
        } else if (options.zoom) {
            return copyrightsZoom(fields, options, abortSignal);
        } else {
            return copyrightsWorld(fields, options, abortSignal);
        }
    }

    function handleServiceCall(requestOptions, abortSignal) {
        return copyrightsQuery(fields, requestOptions, abortSignal).then(modelResponse);
    }
    return serviceFactory(
        fields,
        SERVICE_TYPES.MAP,
        'copyrights',
        handleServiceCall
    )(options, additionalOptions);
}
