import * as defaultEndpoints from './endpoints';
import { validCustomDomain } from '../services/validators';

/**
 * @ignore
 * Resolves endpoint by given name. Enables use of custom domain.
 *
 * To use custom domain set it with `additionalOptions.customDomain` attribute.
 * @example
 * ```js
 * const options = {
 *   key: 'your-key'
 * };
 * const additionalOptions = {
 *   customDomain: 'custom-domain.tomtom.com'
 * }
 * tt.services.copyrights(options, additionalOptions)
 *   .then(successCallback)
 *   .catch(errorCallback);
 * ```
 * Provide URL without protocol prefix (http/https). URL must be in TomTom domain (*.tomtom.com)
 */
export class Endpoints {
    constructor(additionalOptions) {
        if (additionalOptions?.customDomain !== undefined) {
            this._customDomain = validCustomDomain(additionalOptions.customDomain);
            this._customDomain = this._customDomain.replace(new RegExp(/\/$/), '');
        }
    }

    resolve(endpointName) {
        const defaultEndpoint = defaultEndpoints[endpointName];
        if (this._customDomain !== undefined) {
            return this._changeDomain(defaultEndpoint, this._customDomain);
        }
        return defaultEndpoint;
    }

    _changeDomain(endpointUrl, newDomain) {
        return endpointUrl.replace(defaultEndpoints.origin, newDomain);
    }
}
