import { SERVICE_TYPES } from 'Core/serviceTypes';
import {
    string as validateString, integer as validateInteger
} from '../validators';
import {integer as convertInteger} from '../converters';
import urlBuilder from '../../requester/urlBuilder';
import { Endpoints } from '../../endpoints/endpointsManager';
import { serviceFactory } from '../../core';

/**
* Points of Interest (POI) Photos service provides photos of the POI.
* Use this service to generate the URL to retrieve the image.
*
* This service has **specific terms and conditions of usage**. For more information about this service please
* refer to the [POI Photos API documentation](POI_PHOTOS_URL).
*
*
* @class poiPhotos
* @module Services
* @namespace Services.services
* @uses KeyMixin
* @constructor
*
* @param {Object} [options] Options to be passed to the call.
*
* @deprecated
* This API will be discontinued as of 24th of June 2022.
* After this date, poiPhotos method will be removed from Services SDK.
*/
const fields = {
    key: {
        validators: [validateString],
        required: true
    },
    /**
     * @attribute id
     * @param {Number} [options.id] Photo id which is previously retrieved from the
     * {{#crossLink "Services.services.poiDetails"}}POI Details service{{/crossLink}}.
     */
    id: {
        validators: [validateString],
        required: true
    },

    /**
     * The maximum height of the image, which will be returned by the service.
     * If only height is provided, the image will be scaled according to that dimension.
     * If none of dimensions is provided, the service will return the original size image.
     * If width is also provided, service will fit, center and crop the image to fill both desired dimensions.
     * If height provided is bigger than the original image's height, the service will return the image
     * with original height.
     *
     * @attribute height
     * @param {Number} [options.height] The maximum height of the image.
     */
    height: {
        validators: [validateInteger],
        converters: [convertInteger]
    },

    /**
     * The maximum width of the image, which will be returned by the service.
     * If only width is provided, the image will be scaled according to that dimension.
     * If none of dimensions is provided, the service will return the original size image.
     * If height is also provided, service will fit, center and crop the image to fill both desired dimensions.
     * If width provided is bigger than the original image's width, the service will return the image
     * with original width.
     *
     * @attribute width
     * @param {Number} [options.width] The maximum width of the image.
     */
    width: {
        validators: [validateInteger],
        converters: [convertInteger]
    }
};

export function poiPhotos(options, additionalOptions) {
    function handleServiceCall(data) {
        const endpoints = new Endpoints(additionalOptions);
        return 'https://' + urlBuilder(endpoints.resolve('poiPhotosEndpoint'), {}, data);
    }
    return serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'poiPhotos',
        handleServiceCall
    )(options, additionalOptions);
}
